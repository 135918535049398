(function(factory){
    if (typeof define === 'function' && define.amd){
        define(['jquery'],factory);
    } else if (typeof module==='object' && module.exports) {
        module.exports=factory(require('jquery'));
    } else {
        factory(window.jQuery);
    }
}(function($){
  $.extend($.summernote.plugins,{
    imageModal: function(context){
        var ui = $.summernote.ui;
        var $editable = context.layoutInfo.editable;
        var $img = $($editable.data('target'));
        var isSet = $img.hasClass('img-modal');
        context.memo('button.imageModal', function() {
          var button = ui.buttonGroup([
            ui.button({
              className: isSet ? 'active' : '',
              contents: '<span class="fa fa-object-group"></span>',
              tooltip: 'Open In Modal',
              click: function() {
                var $img = $($editable.data('target'));
                var isSet = $img.hasClass('img-modal');

                if (isSet) {
                  $img.removeClass('img-modal');
                  $(this).removeClass('active');
                } else {
                  $img.addClass('img-modal');
                  $(this).addClass('active');
                }
              }
            })
          ]);
          return button.render();
        });
    }
});

}));