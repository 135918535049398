/*!
 * jQuery plugin
 * What does it do
 */
(function($) {
  var card = [];
  var buffer = [];

  var timerHandle;

  $.fn.swipe = function(opts, callback) {

    // default configuration
    var config = $.extend({}, {
      interdigitTimeout: 350
    }, opts);

    // main function
    function AttachSwipe($ele) {
      $ele.on('keypress', function(e) {
        //e.preventDefault();

        if (e.which === 13) {
          card.push(buffer.join(''));
          buffer = [];
        } else {
          buffer.push(String.fromCharCode(e.which));
        }

        clearTimeout(timerHandle);
        timerHandle = setTimeout(function () {
          typeof callback === 'function' && callback(card);
          card = [];
          buffer = [];
          $ele.val('');
        }, config.interdigitTimeout);
      });
    }

    // initialize every element
    this.each(function() {
      AttachSwipe($(this));
    });

    return this;
  };
})(jQuery);