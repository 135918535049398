(function (factory) {
  /* global define */
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    module.exports = factory(require('jquery'));
  } else {
    // Browser globals
    factory(window.jQuery);
  }
}(function ($) {
  // Extends plugins for adding templates.
  //  - plugin is external module for customizing.
  $.extend($.summernote.plugins, {
    /**
     * @param {Object} context - context object has status of editor.
     */
    'eventlink': function (context) {
      // ui has renders to build ui elements.
      //  - you can create a button with `ui.button`
      var ui      = $.summernote.ui;
      var options = context.options.eventlink;

      // add hello button
      context.memo('button.eventlink', function () {
        // create button
        var button = ui.buttonGroup([
          ui.button({
            className: 'dropdown-toggle',
            contents: '<span class="event-link"/><i class="fa fa-file-text"></i> <span class="caret"></span>',
            tooltip: 'Insert Link to Event',
            data: {
              toggle: 'dropdown'
            }
          }),
          ui.dropdown({
            className: 'dropdown-template',
            items: options.list,
            template: function (item) {
              return item.name;
            },
            click: function (event) {
              var $button = $(event.target);
              var value   = $button.data('value');

              context.invoke('editor.createLink', {
                text: $button.html(),
                url: value,
                newWindow: false
              });
            }
          })
        ]);

        // create jQuery object from button instance.
        return button.render();
      });
    }
  });
}));